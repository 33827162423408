import { timeFormat, numberFormat, ParseFloatFormat } from '@custom/index'
// 资产管理tabs
export const assetsManagementTabOptions = [
  {
    label: '资产入库',
    name: 'assetsWarehousing'
  },
  {
    label: '资产分类',
    name: 'assetsClassification'
  },
  {
    label: '供应商',
    name: 'supplier'
  },
  {
    label: '资产盘点',
    name: 'inventory'
  },
  {
    label: '库房管理',
    name: 'warehouse'
  }
]

//资产入库 一级界面
export const assetsOneLevelColumn = [
  {
    type: 'index',
    label: '序号',
    width: 60
  },
  {
    prop: 'assetType',
    label: '资产类别'
  },
  {
    prop: 'assetsTypeName',
    label: '设备类型'
  },
  {
    prop: 'count',
    label: '数量'
  }
]
//资产入库 列表
/*export const assetsWarehousingTableColumn = [
    {
        type: "index",
        label: "序号",
        width: 60
    },
    {
        prop: "asstesSn",
        label: "资产编码",
        width: 200,
    },
    {
        prop: "state",
        label: "状态",
        width: 150,
        /!*render: (h, {row}) => {
            return h("span", row.state===1?"闲置":"");
        },*!/
    },
    {
        prop: "asstesName",
        label: "资产名称",
        width: 150,
    },
    {
        prop: "assetType",
        label: "资产类别",
        width: 150,
    },
    {
        prop: "model",
        label: "规格型号",
        width: 150,
    },
    {
        prop: "price",
        label: "金额",
        width: 150,
        render: (h, {row}) => {
            return h("span", numberFormat(row.price / 100));
        },
    },
    /!*{
        prop: "asstesSn",
        label: "SN号",
        width: 200,
    },*!/
    {
        prop: "communityName",
        label: "使用公司",
        width: 150,
    },
    {
        prop: "name",
        label: "使用部门",
        width: 150,
    },
    {
        prop: "leasor",
        label: "使用人",
        width: 150,
    },
    /!*{
        prop: "",
        label: "区域",
        width: 150,
    },*!/
    {
        prop: "stockName",
        label: "存放地点",
        width: 150,
    },
    {
        prop: "intakeDate",
        label: "购入时间",
        width: 150,
        render: (h, {row}) => {
            let {intakeDate} = row;
            intakeDate = intakeDate ? timeFormat(new Date(intakeDate), "yyyy-MM-dd HH:mm:ss") : "";
            return h("span", intakeDate);
        }
    },
    {
        prop: "stocker",
        label: "入库人",
        width: 150,
    },
    {
        prop: "storageDate",
        label: "入库时间",
        width: 150,
        render: (h, {row}) => {
            let {storageDate} = row;
            storageDate = storageDate ? timeFormat(new Date(storageDate), "yyyy-MM-dd HH:mm:ss") : "";
            return h("span", storageDate);
        }
    },
];*/
export const assetsWarehousingTableColumn = [
  {
    type: 'index',
    label: '序号',
    width: 60
  },
  {
    prop: 'assetName',
    label: '资产名称'
  },
  {
    prop: 'assetType',
    label: '资产类别'
  },
  {
    prop: 'assetsTypeName',
    label: '设备类型'
  },
  {
    prop: 'model',
    label: '规格型号'
  },
  {
    prop: 'price',
    label: '单价',
    render: (h, { row: { price, unit } }) => {
      return h('span', numberFormat(ParseFloatFormat(price)) + '/' + unit)
    }
  },
  {
    prop: 'number',
    label: '采购数量'
  },
  {
    prop: 'quantityCount',
    label: '实际数量'
  },
  {
    prop: 'supplierName',
    label: '供应商'
  }
]
//资产详情 列表
export function assetsDetailsTableColumn (_this){
  return[
    {
      type: 'selection',
      width: 70
    },
    {
      prop: 'asstesSn',
      label: '资产编码'
    },
    // {
    //     prop: "assetType",
    //     label: '资产类别',
    // },
    {
      prop: 'asstesName',
      label: '资产名称'
    },
    {
      prop: 'communityName',
      label: '小区名称',
      render: (h, { row }) => {
        let { communityName } = row
        return h("el-link", {
          props: {type: "primary", underline: false},
          on: {
            click() {
              _this.$router.push({name: "community-management", params: {data: {name:communityName}}});
            }
        }
      }, communityName);
      }
    },
    {
      prop: 'name',
      label: '房间名',
      width: 200,
      render: (h, { row }) => {
        let { name } = row
        return h("el-link", {
          props: {type: "primary", underline: false},
          on: {
            click() {
              _this.$router.push({name: "house-resource", query: {data: {apartmerntName: name}}});
            }
        }
      }, name);
      }
    },
    {
      prop: 'leasor',
      label: '使用人',
      render: (h, { row }) => {
        let { leasor } = row
        return h("el-link", {
          props: {type: "primary", underline: false},
          on: {
            click() {
              _this.$router.push({name: "tenant-management", params: {data: {leasorName:leasor}}});
            }
        }
      }, leasor);
      }
    },
    {
      prop: 'state',
      label: '状态'
    },
    {
      prop: 'company',
      label: '所属公司'
    },
    {
      prop: 'contractBatch',
      label: '合同批次'
    },
    {
      prop: 'intakeDate',
      label: '购入时间',
      render: (h, { row }) => {
        let { intakeDate } = row
        intakeDate = intakeDate ? timeFormat(new Date(intakeDate)) : ''
        return h('span', intakeDate)
      }
    },
    {
      prop: 'storageDate',
      label: '入库时间',
      render: (h, { row }) => {
        let { storageDate } = row
        storageDate = storageDate ? timeFormat(new Date(storageDate)) : ''
        return h('span', storageDate)
      }
    },
    {
      prop: 'verifyTime',
      label: '核验时间',
      render: (h, { row }) => {
        let { verifyTime } = row
        verifyTime = verifyTime ? timeFormat(new Date(verifyTime)) : ''
        return h('span', verifyTime)
      }
    },
    {
      prop: 'stocker',
      label: '入库人'
    },
    {
      prop: 'verifier',
      label: '核验人'
    },
  ]
} 
 

//资产详情盘点 列表
export const assetsDetailsInventoryTableColumn = [
  {
    type: 'index',
    label: '序号',
    width: 60
  },
  {
    prop: 'inventoryTime',
    label: '盘点时间',
    render: (h, { row }) => {
      let { inventoryTime } = row
      inventoryTime = inventoryTime
        ? timeFormat(new Date(inventoryTime), 'yyyy-MM-dd HH:mm:ss')
        : ''
      return h('span', inventoryTime)
    }
  },
  {
    prop: 'inventoryUserName',
    label: '盘点人'
  },
  {
    prop: 'apartment',
    label: '盘点房间',
    render: (h, { row }) => {
      const { apartment } = row
      const apartmentName = apartment ? apartment.name : '无'
      return h('span', apartmentName)
    }
  },
  {
    prop: 'inventoryStatusName',
    label: '资产状态'
  },
  {
    prop: 'inventoryInfo',
    label: '详情'
  }
]
// 资产报修详情列表
export const tableRepairColumn = [
  {
    prop: 'innerMap.repairNo',
    label: '维修编号'
  },
  {
    prop: 'innerMap.repairDetail',
    label: '维修内容'
  },
  {
    prop: 'innerMap.repairType',
    label: '类型'
  },
  {
    prop: 'innerMap.repairName',
    label: '报修人'
  },
  {
    prop: 'innerMap.createTime',
    label: '报修时间',
    render: (h, { row }) => {
      const {
        innerMap: { createTime }
      } = row
      return createTime ? timeFormat(new Date(createTime)) : ''
    }
  },
  {
    prop: 'innerMap.repairProject',
    label: '房间'
  },
  {
    prop: 'innerMap.repairMan',
    label: '维修人'
  },
  {
    prop: 'innerMap.commentdate',
    label: '维修时间',
    render: (h, { row }) => {
      const {
        innerMap: { commentdate }
      } = row
      return commentdate ? timeFormat(new Date(commentdate)) : ''
    }
  },
  {
    prop: 'innerMap.dealState',
    label: '跟进状态'
  },
  {
    prop: 'innerMap.confirmor',
    label: '确认人'
  }
]
//资产分类 列表
export const classificationTableColumn = [
  {
    type: 'index',
    label: '序号',
    width: 100
  },
  {
    prop: 'code',
    label: '分类编码',
    sortable: true,
    className: 'column-tree'
  },
  {
    prop: 'codeName',
    label: '分类名称'
  }
]
//供应商 列表
export const supplierTableColumn = [
  {
    prop: 'state',
    label: '状态',
    width: 150,
    render: (h, { row }) => {
      let { state } = row
      return state && state === 1 ? '正常' : '禁用'
    }
  },
  {
    prop: 'companyName',
    label: '供应商',
    width: 150
  },
  {
    prop: 'linkMan',
    label: '联系人'
  },
  {
    prop: 'phone',
    label: '联系人电话'
  },
  {
    prop: 'paymentBank',
    label: '付款开户行'
  },
  {
    prop: 'account',
    label: '付款开户账号'
  },
  {
    prop: 'remark',
    label: '备注'
  }
]

//资产盘点 列表
export function inventoryTableColumn (that) {
  return [
    {
      prop: 'blitemSn',
      label: '盘点编号',
      width: 150
    },
    {
      prop: 'state',
      label: '状态',
      width: 150
    },
    {
      prop: 'name',
      label: '盘点单名称',
      render: (h, { row }) => {
        let { name } = row
        return h(
          'el-link',
          {
            props: { type: 'primary', underline: false },
            on: {
              click () {
                that.$router.push({
                  name: 'inventory-details',
                  params: { data: row }
                })
              }
            }
          },
          name
        )
      }
    },
    {
      prop: '',
      label: '完成',
      render: (h, { row }) => {
        let { stateCode } = row
        return h('el-switch', {
          props: { value: stateCode === 2 },
          on: {
            change () {
              if (stateCode !== 2) that.assetsBlitemStatus(row)
            }
          }
        })
      }
    },
    // {
    //   prop: '',
    //   label: '盘点报告',
    //   render: (h, { row }) => {
    //     return h(
    //       'el-link',
    //       {
    //         props: { type: 'primary', underline: false },
    //         on: {
    //           click () {
    //             that.blitemReport(row)
    //           }
    //         }
    //       },
    //       '下载'
    //     )
    //   }
    // },
    {
      prop: 'creatAdmin',
      label: '创建人'
    },
    {
      prop: 'creatTime',
      label: '创建时间',
      render: (h, { row }) => {
        let { creatTime } = row
        creatTime = creatTime
          ? timeFormat(new Date(creatTime), 'yyyy-MM-dd HH:mm:ss')
          : ''
        return h('span', creatTime)
      }
    }
  ]
}

//库房管理 列表
export const warehouseTableColumn = [
  {
    prop: 'stockSn',
    label: '库房编码',
    width: 150
  },

  {
    prop: 'stockName',
    label: '库房名称'
  },
  {
    prop: 'company',
    label: '所属公司'
  }
  /*{
        prop: "",
        label: "状态",
        width: 150,
    },*/
]
//资产调拨 列表
export const assetTransferColumn = [
  {
    type: 'selection',
    width: 60
  },
  {
    prop: 'allocateSn',
    label: '调拨编号',
    width: 150
  },
  {
    prop: 'state',
    label: '办理状态',
    width: 150
  },
  {
    prop: 'assetsSn',
    label: '资产编号',
    width: 150
  },
  {
    prop: 'assetsName',
    label: '资产名称',
    width: 150
  },
  {
    prop: 'assetsType',
    label: '资产类别',
    width: 150
  },
  {
    prop: 'model',
    label: '规格型号',
    width: 150
  },
  {
    prop: 'createTime',
    label: '调拨时间',
    width: 150,
    render: (h, { row }) => {
      let { createTime } = row
      createTime = createTime
        ? timeFormat(new Date(createTime), 'yyyy-MM-dd HH:mm:ss')
        : ''
      return h('span', createTime)
    }
  },
  {
    prop: 'createAdmin',
    label: '调出管理员',
    width: 150
  },
  {
    prop: 'oldCompany',
    label: '调出公司',
    width: 150
  },
  {
    prop: 'company',
    label: '调入公司',
    width: 150
  },
  {
    prop: 'createAdmin',
    label: '调入管理员',
    width: 150
  },
  {
    prop: '',
    label: '调入日期',
    width: 150,
    render: (h, { row }) => {
      let { createTime } = row
      createTime = createTime
        ? timeFormat(new Date(createTime), 'yyyy-MM-dd HH:mm:ss')
        : ''
      return h('span', createTime)
    }
  },
  {
    prop: 'remark',
    label: '调拨说明',
    width: 150
  }
]
//资产调拨 新增调拨 资产列表
export const assetTransferToassetColumn = [
  {
    type: 'selection',
    width: 60
  },
  {
    prop: 'asstesSn',
    label: '资产编码',
    width: 100
  },
  {
    prop: 'asstesName',
    label: '资产名称',
    width: 100
  },
  {
    prop: 'assetType',
    label: '资产类别',
    width: 100
  },
  {
    prop: 'model',
    label: '规格型号',
    width: 100
  },
  {
    prop: '',
    label: '所属公司'
  },
  /*{
        prop: "",
        label: "SN号",
        width: 100,
    },*/
  {
    prop: '',
    label: '使用公司',
    width: 100
  },
  {
    prop: '',
    label: '使用部门',
    width: 100
  },
  {
    prop: '',
    label: '使用人',
    width: 100
  }
]

//盘点详情 列表
export function inventoryDetailsTableColumn (that) {
  return [
    {
      prop: 'status',
      label: '盘点状态',
      width: 100
    },
    {
      prop: '',
      label: '资产类别',
      width: 100,
      render: (h, { row }) => {
        let {
          assetDetail: {
            assets: { assetType }
          }
        } = row
        return h('span', assetType)
      }
    },
    {
      prop: '',
      label: '资产编码',
      width: 150,
      render: (h, { row }) => {
        let {
          assetDetail: { assetsSn }
        } = row
        return h('span', assetsSn)
      }
    },
    {
      prop: '',
      label: '资产名称',
      width: 100,
      render: (h, { row }) => {
        let {
          assetDetail: { assetsName }
        } = row
        return h('span', assetsName)
      }
    },
    {
      prop: 'assetState',
      label: '资产状态'
    },
    {
      prop: '',
      label: '规格型号',
      width: 100,
      render: (h, { row }) => {
        let {
          assetDetail: {
            assets: { model }
          }
        } = row
        return h('span', model)
      }
    },
    {
      prop: '',
      label: '金额',
      width: 100,
      render: (h, { row }) => {
        let {
          assetDetail: {
            assets: { price }
          }
        } = row
        return h('span', numberFormat(price / 100))
      }
    },
    {
      prop: '',
      label: '使用公司',
      width: 100,
      render: (h, { row }) => {
        let {
          blitem: { companyUuid }
        } = row
        return h('span', companyUuid)
      }
    },
    {
      prop: '',
      label: '使用部门',
      width: 200,
      render: (h, { row }) => {
        let {
          assetDetail: { apartment }
        } = row
        let name = ''
        if (apartment) name = apartment.name
        return h('span', name)
      }
    },
    {
      prop: '',
      label: '使用人',
      render: (h, { row }) => {
        let {
          assetDetail: { apartment }
        } = row
        let name = ''
        if (apartment) {
          let { currentBoundLeasor } = apartment
          if (currentBoundLeasor) name = currentBoundLeasor.name
        }
        return h('span', name)
      }
    },
    {
      prop: '',
      label: '区域',
      width: 150,
      render: (h, { row }) => {
        let {
          blitem: { region }
        } = row
        return h('span', region)
      }
    },
    {
      prop: 'stockName',
      label: '存放地点'
    },
    /*{
            prop: "",
            label: "来源",
        },*/
    {
      prop: '',
      label: '购入时间',
      width: 100,
      render: (h, { row }) => {
        let {
          assetDetail: {
            assets: { intakeDate }
          }
        } = row
        intakeDate = intakeDate
          ? timeFormat(new Date(intakeDate), 'yyyy-MM-dd')
          : ''
        return h('span', intakeDate)
      }
    },
    {
      prop: 'adminName',
      width: 100,
      label: '创建人'
    },
    {
      prop: '',
      label: '创建时间',
      width: 100,
      render: (h, { row }) => {
        let {
          assetDetail: {
            assets: { storageDate }
          }
        } = row
        storageDate = storageDate
          ? timeFormat(new Date(storageDate), 'yyyy-MM-dd')
          : ''
        return h('span', storageDate)
      }
    }
  ]
}
